.container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.billingUtilizationContainer{
    display: flex;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.billingUtilizationLabel{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.progressContainer{
    display: flex;
    width: 160px;
    gap: 8px;

    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.progressItem{
    height: 6px;
    flex: 1 0 0;

    border-radius: 8px;
    background: var(--Base-Components-Grey-dark, #C0BAC7);
}

.tooltipContainer{
    display: flex;
    padding: 12px;

    border-radius: var(--Primary, 8px);
    background: var(--Inv-Components-Grey-subtle, #1A191B);

    align-items: center;
    gap: 8px;
}

.primaryContent{
    color: var(--Inv-Text-grey-highemp, #EFEEF0);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.secondaryCotent{
    color: var(--Inv-Text-grey-midemp, #B7B2BC);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}