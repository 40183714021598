.container{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 12px;
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Base-Layers-layer-primary, #FFF);

    overflow: hidden;
}

.copyToClipboard{
    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);

    cursor: pointer;
}

.copyToClipboard:hover{
    color: var(--Base-Text-brand-highemp, #33265F);
    
    border-bottom: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
}