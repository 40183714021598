.container{
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--Base-Components-Yellow-dark, #E4A944);
    background: var(--Base-Components-Yellow-subtle, #FFFBE0);
}

.primaryContent{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.approvalButton{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);

    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: pointer;
}