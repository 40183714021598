.container{
    display: flex;
    padding: 14px 16px;
    align-items: center;
    gap: 2px;
    align-self: stretch;
    justify-content: space-between;
    
    position: sticky;
    top: 0;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background-color: #fff;
}

.headingContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.primaryContent{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.descriptionContainer{
    display: flex;
    align-items: center;
    gap: 8px;
}

.secondaryContent{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}