.container{
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.contentTypo{
    white-space: pre-line;
}