.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.header {
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);

    font-family: "Founders Grotesk Medium";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%;
    /* 46.4px */
    letter-spacing: 0.04px;
}

.subHeading {
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
}