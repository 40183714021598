.container{
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background: var(--Base-Layers-layer-primary, #FFF);
    cursor: pointer;

    border-radius: 50%;
}

.container:hover{
    /* cursor: pointer;
    background-color: #FCF8FF; */
}