.listItemContainer{
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
    
    cursor: context-menu;
}

.billingIcon{
    width: var(--Container, 16px);
    height: var(--Container, 16px);
}

.rightItemContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    flex: 1 0 0;
}

.rightTopContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.rightBottomContainer{
    display: flex;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;
}

.textOuterContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.listItemBaseContent{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.mainText{
    overflow: hidden;
    color: var(--Base-Text-grey-highemp, #211F26);
    text-overflow: ellipsis;
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
}

.nonHighlightedText{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;

    display: flex;
    gap: 4px;

}

.lightHighlightedText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.iconContainer{
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.utilizedContent{
    background: var(--Base-Components-Green-low, #E5FAF2);
    color: var(--Base-Text-green-midemp, #2A8466);
}

.checkComplianceListContent{
    display: flex;
    justify-content: flex-end;
}