.container{
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    cursor: pointer;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.container:hover{
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.arrowIconContainer{
    display: flex;
    align-items: center;
}

.mainOuterContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.headingText{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    color: var(--Base-Text-grey-highemp, #211F26);
    text-overflow: ellipsis;
    line-break: anywhere;

    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.secondaryText{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    
    flex: 1 0 0;
    overflow: hidden;
    color: var(--Base-Text-grey-midemp, #66636D);
    text-overflow: ellipsis;
    line-break: anywhere;

    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.084px;
}

.tertiaryText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.showMoreIcon{
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.notesIcon{
    width: 24px;
    height: 24px;
}

.submitttedByLabel{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.submittedByContent{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.notesTypeContainer{
    display: flex;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Components-Grey-low, #F1EFF3);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.headerTopContainer{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    align-self: stretch;
}

.sliderWindowClassName{
    max-width: 800px;
    min-width: 800px;
    overflow: auto !important;
}

.containerClassName{
    overflow: auto !important;
}