.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.labelTypo{
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--Base-Text-grey-highemp, #211F26);
    text-overflow: ellipsis;
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}