.container {
    display: flex;
    width: 100%;
    height: 344px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    flex-shrink: 0;
}

.primary {
    color: var(--Base-Text-grey-midemp, #66636D);
    text-align: center;

    /* Label/Label 2 */
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;

    align-self: stretch;
}

.secondary {
    align-self: stretch;
    color: var(--Base-Text-grey-midemp, #66636D);
    text-align: center;

    /* Caption/Caption 3 */
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    /* 17.76px */
    letter-spacing: 0.072px;
}