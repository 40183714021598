.container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--Muted-Alpha-500, rgba(21, 18, 23, 0.48));

    display: flex;
    flex-direction: row-reverse;

    z-index: 499;
}

.sliderWindowContainer{
    display: flex;
    width: 960px;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;

    background: var(--Background-Layer-Base-Primary-Rest, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(33, 31, 38, 0.16);
}