.container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.content{
    color: var(--Base-Text-grey-highemp, #211F26);

    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}