.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 12px;
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Base-Layers-layer-primary, #FFF);

    overflow: hidden;
}