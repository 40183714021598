.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    
    border-radius: 12px;
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.toggleIcon{
    width: 20px;
    height: 20px;

    cursor: pointer;
}

.toggleColumnContainer{
    display: flex;
    gap: 8px;
}

.columnTypoInnerClass{
    display: flex;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    padding-left: 0 !important;
}

.columnInnerTypo{
    display: flex;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    padding-left: 0 !important;

}

.topHeaderBlock{
    display: flex;
    justify-content: flex-start;
    width: 135px;
}

.topHeaderColumn{
    background: var(--Background-Layer-Base-Secondary-Rest, #FAF9FB);
}

.tableRow{
    display: flex;
    padding: 14px 16px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.statusContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
    gap: 4px;

    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;

    border-radius: 200px;
}

.utilized{
    background: var(--Base-Components-Green-low, #E5FAF2);
    color: var(--Base-Text-green-midemp, #2A8466);
}

.nonUtilized{
    background: var(--Base-Components-Yellow-low, #FFF6BD);
    color: var(--Base-Text-yellow-midemp, #86612D);
}

.statusOuterContainer{
    display: flex;
    align-items: center;
    gap: 10px;
}

.statusRange{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}
