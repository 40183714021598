.columnContainerClass{
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 8px;
    width: 175px;
    justify-content: flex-start;

    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.innerClassName{
    padding-left: 0 !important;
}