.container{
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 42px;
    flex: 1 0 0;
    align-self: stretch;
}

.outerContainer{
    display: flex;
    flex-direction: column;
}