.container{
    display: flex;
    padding: 16px;
    justify-content: center;
    gap: 16px;

    background: var(--Base-Layers-layer-primary, #FFF);
}

.headerContainer{
    display: flex;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    background: var(--Base-Layers-layer-primary, #FFF);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.iconContainer{
    display: flex;
    height: auto;
    cursor: pointer;
    padding: 8px;
    position: absolute;
    top: 16px;
}

.rightIconContainer{
    right : 16px
}

.leftIconContainer{
    left: 16px;
}

.normalIconContainer{}

.normalIconContainer:hover{
    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.nonActive{
    background: var(--Base-Components-Violet-subtle, #FFF);
    cursor: not-allowed;
}

.arrowIcon{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}