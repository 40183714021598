.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.rowItem{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.itemBlock{
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    background: var(--Base-Layers-layer-primary, #FFF);
}

.itemBlockLabel{
    border-right: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}