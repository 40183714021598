.container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.buttonContainer{
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-top: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: #FFF;
}

.notesButton{
    display: flex;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.notesButton:hover{
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Hover, linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.submitButton{
    width: fit-content;
}