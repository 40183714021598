.container{
    font-size: medium;
    height: 100%;
    display: flex;
    align-items: center;

    gap:4px;

    color: var(--Base-Text-grey-midemp, #66636D);

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.activeRow{
    color: var(--Base-Text-brand-primary, #854DC4);
}

.mainOuterLabel{
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.readyToBill{
    background: var(--Base-Components-Green-low, #E5FAF2);
    color: var(--Base-Text-green-midemp, #2A8466);
}

.needApproval{
    background: var(--Base-Components-Yellow-low, #FFF6BD);
    color: var(--Base-Text-yellow-midemp, #86612D);
}

.tagsContainer{
    display: flex;
    gap: 4px;
}