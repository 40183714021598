.container{
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    border-radius: 34px;
    background: var(--Base-Components-Grey-low, #F1EFF3);
}

.tabItem{
    display: flex;
    flex: 1;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    display: flex;
    padding: 9px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    border: 1px solid var(--Base-Stroke-Brand-low, #F1EFF3);

    cursor: pointer;
}

.activeTab{
    border-radius: var(--Circle, 200px);
    background: var(--Base-Layers-layer-primary, #FFF);
    box-shadow: 0px 1px 8px 0px var(--Brand-Violet-Light-Alpha-Alpha04, rgba(169, 104, 222, 0.16));
    color: var(--Base-Text-brand-primary, #854DC4);
}

.tabItem:hover{
    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Brand-low, #E8CAFE);
}