.container{
    display: flex;
    padding: 3px;
    justify-content: center;
    align-items: center;

    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);

    cursor: pointer;
}

.container:hover{
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Hover, linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.innerContainer{
    display: inline-flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: var(--Primary, 8px);
    background: #fff;
}

.contentTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}