.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.labelContainer{
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.timePicker{
    width: 100%;
    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;
}