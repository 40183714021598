.container{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}

.smartNoteApprovalContainer{
    display: flex;
    align-items: center;
    gap: 8px;
}

.innerContentClass{
    color: var(--Base-Text-grey-midemp, #66636D) !important;
    font-family: "DM Mono" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.056px;
}

.smartNoteLabel{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.approvalContainer{
    display: flex;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Circle, 200px);

    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
    text-transform: none;

}

.yellowTab{
    color: var(--Base-Text-yellow-midemp, #86612D);
    background: var(--Base-Components-Yellow-low, #FFF6BD);
}

.greenTab{
    background: var(--Base-Components-Green-low, #E5FAF2);
    color: var(--Base-Text-green-midemp, #2A8466);
}