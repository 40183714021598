.container{
    align-items: flex-start;
    align-self: stretch;

    max-height: 303px;
    overflow: auto;
}

.dropDownContent{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.dropdownLabelContainer{
    display: flex;
    gap: 8px;
    align-items: center;
    width: max-content;
}

.dropDownLabel{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.tickIcon{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.markUpContainer{
    display: flex;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: fit-content;

    border-radius: var(--Circle, 200px);
    background: var(--Base-Components-Yellow-low, #FFF6BD);

    color: var(--Base-Text-yellow-midemp, #86612D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.suffixContentTypo{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.billedTypo{
    display: flex;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Circle, 200px);

    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;

    color : #86612D;
    background-color: #FFF6BD;

    width: fit-content;
}

.billedBill{
    background-color: #E5FAF2 !important;
    color: #2A8466 !important;
}