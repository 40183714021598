.container{
    display: flex;
    padding: 14px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    flex: 1 0 0;

    background: var(--Background-Layer-Base-Secondary-Rest, #FAF9FB);
}

.mainContent{
    display: flex;
    align-items: center;
    gap: 8px;
}

.arrowIcon{
    width: 20px;
    height: 20px;
}

.mainContentTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}