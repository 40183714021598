.container{
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.icon{
    width: 20px;
    height: 20px;
}

.contentTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}