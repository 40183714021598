.container{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-radius: var(--Container, 16px);
    overflow: hidden;
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}
.balancedPlateAndMedicationAdherenceContainer{
    display: flex;
    align-items: center;
    align-self: stretch;

    display: flex;
padding-left: 24px;
padding-right: 24px;
align-items: center;
gap: 8px;
flex: 1 0 0;
border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
background: #fff;
}
    