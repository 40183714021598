.container{
    display: flex;
    height: 44px;
    padding: 12px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    flex: 1 0 0;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.arrowContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.arrowIcon{
    width: 16px;
    height: 16px;

    cursor: pointer;
}

.inputBox{
    border: none;
    width: 10px;
}

.inputBox:focus{
    outline: none;
    border: none;
}

.increasedInput{
    width: 19px;
}

.fullWidth{
    width: 100%
}