.container{
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    width: 100%;

    border-top: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}